export const formations = {
  "3-4-2-1": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 25, "y": 75, "position": "CB" },
    "3": { "x": 50, "y": 75, "position": "CB" },
    "4": { "x": 75, "y": 75, "position": "CB" },
    "5": { "x": 15, "y": 55, "position": "LM" },
    "6": { "x": 38, "y": 55, "position": "CM" },
    "7": { "x": 62, "y": 55, "position": "CM" },
    "8": { "x": 85, "y": 55, "position": "RM" },
    "9": { "x": 30, "y": 35, "position": "CF" },
    "10": { "x": 70, "y": 35, "position": "CF" },
    "11": { "x": 50, "y": 22, "position": "ST" }
  },
  "3-4-3": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 25, "y": 75, "position": "CB" },
    "3": { "x": 50, "y": 75, "position": "CB" },
    "4": { "x": 75, "y": 75, "position": "CB" },
    "5": { "x": 15, "y": 55, "position": "LM" },
    "6": { "x": 38, "y": 55, "position": "CM" },
    "7": { "x": 62, "y": 55, "position": "CM" },
    "8": { "x": 85, "y": 55, "position": "RM" },
    "9": { "x": 25, "y": 30, "position": "LW" },
    "10": { "x": 50, "y": 22, "position": "ST" },
    "11": { "x": 75, "y": 30, "position": "RW" }
  },
  "3-4-3 (diamond)": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 25, "y": 75, "position": "CB" },
    "3": { "x": 50, "y": 75, "position": "CB" },
    "4": { "x": 75, "y": 75, "position": "CB" },
    "5": { "x": 15, "y": 50, "position": "LM" },
    "6": { "x": 50, "y": 60, "position": "CDM" },
    "7": { "x": 50, "y": 40, "position": "CAM" },
    "8": { "x": 85, "y": 50, "position": "RM" },
    "9": { "x": 25, "y": 30, "position": "LW" },
    "10": { "x": 50, "y": 22, "position": "ST" },
    "11": { "x": 75, "y": 30, "position": "RW" }
  },
  "3-5-2": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 25, "y": 75, "position": "CB" },
    "3": { "x": 50, "y": 75, "position": "CB" },
    "4": { "x": 75, "y": 75, "position": "CB" },
    "5": { "x": 15, "y": 40, "position": "LM" },
    "6": { "x": 28, "y": 52, "position": "CM" },
    "7": { "x": 50, "y": 60, "position": "CDM" },
    "8": { "x": 72, "y": 52, "position": "CM" },
    "9": { "x": 85, "y": 40, "position": "RM" },
    "10": { "x": 40, "y": 25, "position": "ST" },
    "11": { "x": 60, "y": 25, "position": "ST" }
  },
  "3-5-2 (B)": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 25, "y": 75, "position": "CB" },
    "3": { "x": 50, "y": 75, "position": "CB" },
    "4": { "x": 75, "y": 75, "position": "CB" },
    "5": { "x": 15, "y": 40, "position": "LM" },
    "6": { "x": 30, "y": 60, "position": "CDM" },
    "7": { "x": 50, "y": 45, "position": "CAM" },
    "8": { "x": 70, "y": 60, "position": "CDM" },
    "9": { "x": 85, "y": 40, "position": "RM" },
    "10": { "x": 40, "y": 25, "position": "ST" },
    "11": { "x": 60, "y": 25, "position": "ST" }
  },
  "4-1-2-1-2": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 50, "y": 60, "position": "CDM" },
    "7": { "x": 15, "y": 48, "position": "LM" },
    "8": { "x": 85, "y": 48, "position": "RM" },
    "9": { "x": 50, "y": 35, "position": "CAM" },
    "10": { "x": 35, "y": 20, "position": "ST" },
    "11": { "x": 65, "y": 20, "position": "ST" }
  },
  "4-1-2-1-2 (narrow)": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 50, "y": 60, "position": "CDM" },
    "7": { "x": 20, "y": 48, "position": "CM" },
    "8": { "x": 80, "y": 48, "position": "CM" },
    "9": { "x": 50, "y": 35, "position": "CAM" },
    "10": { "x": 35, "y": 20, "position": "ST" },
    "11": { "x": 65, "y": 20, "position": "ST" }
  },
  "4-1-4-1": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 50, "y": 60, "position": "CDM" },
    "7": { "x": 15, "y": 38, "position": "LM" },
    "8": { "x": 35, "y": 45, "position": "CM" },
    "9": { "x": 65, "y": 45, "position": "CM" },
    "10": { "x": 85, "y": 38, "position": "RM" },
    "11": { "x": 50, "y": 20, "position": "ST" }
  },
  "4-1-3-2": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 50, "y": 60, "position": "CDM" },
    "7": { "x": 25, "y": 40, "position": "LM" },
    "8": { "x": 50, "y": 40, "position": "CM" },
    "9": { "x": 75, "y": 40, "position": "RM" },
    "10": { "x": 40, "y": 20, "position": "ST" },
    "11": { "x": 60, "y": 20, "position": "ST" }
  },
  "4-2-2-2": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 40, "y": 58, "position": "CDM" },
    "7": { "x": 60, "y": 58, "position": "CDM" },
    "8": { "x": 30, "y": 40, "position": "CAM" },
    "9": { "x": 70, "y": 40, "position": "CAM" },
    "10": { "x": 40, "y": 20, "position": "ST" },
    "11": { "x": 60, "y": 20, "position": "ST" }
  },
  "4-2-3-1": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 35, "y": 58, "position": "CDM" },
    "7": { "x": 65, "y": 58, "position": "CDM" },
    "8": { "x": 25, "y": 40, "position": "LM" },
    "9": { "x": 50, "y": 40, "position": "CAM" },
    "10": { "x": 75, "y": 40, "position": "RM" },
    "11": { "x": 50, "y": 20, "position": "ST" }
  },
  "4-2-4": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 35, "y": 52, "position": "CDM" },
    "7": { "x": 65, "y": 52, "position": "CDM" },
    "8": { "x": 20, "y": 30, "position": "LW" },
    "9": { "x": 40, "y": 20, "position": "ST" },
    "10": { "x": 60, "y": 20, "position": "ST" },
    "11": { "x": 80, "y": 30, "position": "RW" }
  },
  "4-3-1-2": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 20, "y": 50, "position": "CM" },
    "7": { "x": 50, "y": 55, "position": "CM" },
    "8": { "x": 80, "y": 50, "position": "CM" },
    "9": { "x": 50, "y": 35, "position": "CAM" },
    "10": { "x": 30, "y": 20, "position": "ST" },
    "11": { "x": 70, "y": 20, "position": "ST" }
  },
  "4-3-2-1": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 25, "y": 55, "position": "CM" },
    "7": { "x": 50, "y": 55, "position": "CM" },
    "8": { "x": 75, "y": 55, "position": "CM" },
    "9": { "x": 30, "y": 35, "position": "CF" },
    "10": { "x": 70, "y": 35, "position": "CF" },
    "11": { "x": 50, "y": 20, "position": "ST" }
  },
  "4-3-3": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 25, "y": 55, "position": "CM" },
    "7": { "x": 50, "y": 55, "position": "CM" },
    "8": { "x": 75, "y": 55, "position": "CM" },
    "9": { "x": 20, "y": 30, "position": "LW" },
    "10": { "x": 80, "y": 30, "position": "RW" },
    "11": { "x": 50, "y": 20, "position": "ST" }
  },
  "4-3-3 (att)": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 25, "y": 55, "position": "CM" },
    "7": { "x": 50, "y": 45, "position": "CAM" },
    "8": { "x": 75, "y": 55, "position": "CM" },
    "9": { "x": 20, "y": 30, "position": "LW" },
    "10": { "x": 80, "y": 30, "position": "RW" },
    "11": { "x": 50, "y": 20, "position": "ST" }
  },
  "4-3-3 (def)": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 25, "y": 50, "position": "CM" },
    "7": { "x": 50, "y": 60, "position": "CDM" },
    "8": { "x": 75, "y": 50, "position": "CM" },
    "9": { "x": 20, "y": 30, "position": "LW" },
    "10": { "x": 80, "y": 30, "position": "RW" },
    "11": { "x": 50, "y": 20, "position": "ST" }
  },
  "4-3-3 (false 9)": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 25, "y": 50, "position": "CM" },
    "7": { "x": 50, "y": 60, "position": "CDM" },
    "8": { "x": 75, "y": 50, "position": "CM" },
    "9": { "x": 20, "y": 25, "position": "LW" },
    "10": { "x": 80, "y": 25, "position": "RW" },
    "11": { "x": 50, "y": 30, "position": "CF" }
  },
  "4-4-1-1": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 15, "y": 50, "position": "LM" },
    "7": { "x": 37, "y": 55, "position": "CM" },
    "8": { "x": 63, "y": 55, "position": "CM" },
    "9": { "x": 85, "y": 50, "position": "RM" },
    "10": { "x": 50, "y": 37, "position": "CF" },
    "11": { "x": 50, "y": 20, "position": "ST" }
  },
  "4-4-2": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 15, "y": 50, "position": "LM" },
    "7": { "x": 37, "y": 50, "position": "CM" },
    "8": { "x": 63, "y": 50, "position": "CM" },
    "9": { "x": 85, "y": 50, "position": "RM" },
    "10": { "x": 30, "y": 25, "position": "ST" },
    "11": { "x": 70, "y": 25, "position": "ST" }
  },
  "4-4-2 (B)": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 75, "position": "LB" },
    "3": { "x": 37, "y": 75, "position": "CB" },
    "4": { "x": 63, "y": 75, "position": "CB" },
    "5": { "x": 85, "y": 75, "position": "RB" },
    "6": { "x": 15, "y": 45, "position": "LM" },
    "7": { "x": 37, "y": 55, "position": "CDM" },
    "8": { "x": 63, "y": 55, "position": "CDM" },
    "9": { "x": 85, "y": 45, "position": "RM" },
    "10": { "x": 30, "y": 25, "position": "ST" },
    "11": { "x": 70, "y": 25, "position": "ST" }
  },
  "5-2-3": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 60, "position": "LWB" },
    "3": { "x": 28, "y": 75, "position": "CB" },
    "4": { "x": 50, "y": 75, "position": "CB" },
    "5": { "x": 72, "y": 75, "position": "CB" },
    "6": { "x": 85, "y": 60, "position": "RWB" },
    "7": { "x": 37, "y": 50, "position": "CM" },
    "8": { "x": 63, "y": 50, "position": "CM" },
    "9": { "x": 20, "y": 30, "position": "LW" },
    "10": { "x": 50, "y": 20, "position": "ST" },
    "11": { "x": 80, "y": 30, "position": "RW" }
  },
  "5-3-2": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 60, "position": "LWB" },
    "3": { "x": 28, "y": 75, "position": "CB" },
    "4": { "x": 50, "y": 75, "position": "CB" },
    "5": { "x": 72, "y": 75, "position": "CB" },
    "6": { "x": 85, "y": 60, "position": "RWB" },
    "7": { "x": 25, "y": 40, "position": "LM" },
    "8": { "x": 50, "y": 53, "position": "CM" },
    "9": { "x": 75, "y": 40, "position": "RM" },
    "10": { "x": 35, "y": 25, "position": "ST" },
    "11": { "x": 65, "y": 25, "position": "ST" }
  },
  "5-4-1": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 60, "position": "LWB" },
    "3": { "x": 28, "y": 75, "position": "CB" },
    "4": { "x": 50, "y": 75, "position": "CB" },
    "5": { "x": 72, "y": 75, "position": "CB" },
    "6": { "x": 85, "y": 60, "position": "RWB" },
    "7": { "x": 25, "y": 43, "position": "LM" },
    "8": { "x": 50, "y": 60, "position": "CDM" },
    "9": { "x": 75, "y": 43, "position": "RM" },
    "10": { "x": 50, "y": 35, "position": "CAM" },
    "11": { "x": 50, "y": 20, "position": "ST" }
  },
  "5-4-1 (flat)": {
    "1": { "x": 50, "y": 88, "position": "GK" },
    "2": { "x": 15, "y": 60, "position": "LWB" },
    "3": { "x": 28, "y": 75, "position": "CB" },
    "4": { "x": 50, "y": 75, "position": "CB" },
    "5": { "x": 72, "y": 75, "position": "CB" },
    "6": { "x": 85, "y": 60, "position": "RWB" },
    "7": { "x": 15, "y": 45, "position": "LM" },
    "8": { "x": 37, "y": 45, "position": "CM" },
    "9": { "x": 63, "y": 45, "position": "CM" },
    "10": { "x": 85, "y": 45, "position": "RM" },
    "11": { "x": 50, "y": 25, "position": "ST" }
  }
};